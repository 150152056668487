import React, { useState } from 'react';
import fillerImg from '../../images/filler.png';
import { useHistory } from 'react-router-dom';
import PEIModal from './PEIModal';

const PortfolioElement = (props) => {
	const [moreInfo, setMoreInfo] = useState(false);
	const history = useHistory();

	const goToLink = () => {
		history.push(props.link);
	};
	return (
		<div className='pfo-element' disabled={moreInfo}>
			<img className='pfo-img' src={props.image || fillerImg} alt='' />
			<div className='txt-container'>
				<h1 className='pfo-el-title'>{props.title}</h1>
				<p className='pfo-el-des'>{props.description}</p>
				<div className='pfo-btns'>
					<button
						className='pfo-el-btn-2'
						onClick={() => {
							props.activateModal({
								skills: props.skills,
								frameWroksLibraries: props.frameWroksLibraries,
								date: props.date,
							});
						}}
					>
						More Information
					</button>
					<button className='pfo-el-btn' onClick={goToLink}>
						go To {props.title}
					</button>
				</div>
				{moreInfo ? (
					<PEIModal
						skills={props.skills}
						frameWroksLibraries={props.frameWroksLibraries}
						date={props.date}
					/>
				) : null}
			</div>
		</div>
	);
};

export default PortfolioElement;
