import React from 'react';
import NavBar from './components/Home/Navbar';
import Main from './components/Home/Main';
import Portfolio from './components/Portfolio/Portfolio';
import Weather from './components/Portfolio/weather/Weather';
import { Switch, Route, Redirect } from 'react-router-dom';
import Palentar from './components/Portfolio/Palentar/Palentar';

function App() {
	return (
		<div className='App'>
			<NavBar />
			<Switch>
				<Route path='/Portfolio/Palentar' exact component={Palentar} />
				<Route path="/Portfolio/weather-app" exact component={Weather}/>
				<Route path='/Portfolio' exact component={Portfolio} />
				<Route path='/Home' component={Main} />
				<Route path='/'>
					<Redirect to='/Home' />
				</Route>
				{/* <Main /> */}
			</Switch>
		</div>
	);
}

export default App;
