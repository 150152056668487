import React from 'react';

const Palentar = () => {
	return (
		<div className='pltr-frame-container'>
			<iframe
				title='Palentar'
				className='pltr-frame'
				src='https://palentar.com'
				frameborder='0'
			></iframe>
			<a className='pltr-link' href='https://palentar.com'>
				Go to actual Website
			</a>
		</div>
	);
};

export default Palentar;
