import React, { useRef, useEffect } from 'react';

const PEIModal = (props) => {
	const modal = useRef();
	let months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'November',
		'December',
	];
	const createDateString = () => {
		if (props.date) {
			let dateString = `${
				months[props.date.getMonth()]
			} ${props.date.getFullYear()}`;
			return dateString;
		} else {
			return;
		}
	};
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		// modal.current.scrollIntoView({
		// 	block: 'start',
		// 	behvaior: 'smooth',
		// });
	}, []);
	return (
		<div className='pei-modal-container'>
			<div className='pei-modal' ref={modal}>
				<button className='quit' onClick={() => props.turnOff()}>
					X
				</button>
				<p className='pfo-el-skills'>
					<span style={{ fontWeight: 'bold' }}>Skills: </span>
					{props.skills}
				</p>
				<p className='pfo-el-fr-lb'>
					<span style={{ fontWeight: 'bold' }}>Technologies: </span>
					{props.frameWroksLibraries}
				</p>
				<p className='pfo-date'>
					<span style={{ fontWeight: 'bold' }}>Date: </span>
					{createDateString()}
				</p>
			</div>
		</div>
	);
};

export default PEIModal;
