import React from 'react';
import PortfolioBoxes from './PortfolioBoxes';

const Main = () => {
	return (
		<div className='main'>
			<h1 className='main-message'>I'm Shady Guindi, Welcome to my Website</h1>
			<PortfolioBoxes />
		</div>
	);
};

export default Main;
