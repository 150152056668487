import React from 'react';
import axios from 'axios';
import {useState} from 'react';
import './weather.css';


const Weather = () => {
	const [externalFile, setExternalFile] = useState()

	
    return (
    	<div className='wthr-frame-container' dangerouslySetInnerHTML={externalFile}>

            <iframe className="wthr-frame" src="/weather/main.html" frameborder="0"></iframe>  
			{/* <iframe
				title='Weather App'
				className='pltr-frame'
				src='./main.html'
				frameborder='0'
			></iframe>
			<a className='pltr-link' href="./main.html">
				Go to actual Website
			</a>*/}
				<p
				className="new-page-link" 
		
					onClick={
					()=>{
					window.open("/weather/main.html")
					}
					}>Open Separate Page</p>
			</div>
		
    )
}

export default Weather
