import React from 'react';
import filler from '../../images/filler.png';
import { useHistory } from 'react-router-dom';
const PortfolioBox = (props) => {
	const history = useHistory();

	const toRef = () => {
		history.push(props.reference);
	};
	return (
		<div className='portfolio-box'>
			<img className='img-preview' src={props.image || filler} alt='image' />
			<h2 className='project-title'>{props.title}</h2>

			<button className='see-project' onClick={toRef} disabled={props.comingSoon?true:false}>
				{props.comingSoon?"Coming Soon":"See Project"}
			</button>
		</div>
	);
};

export default PortfolioBox;
