import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
	return (
		<header className='nav-bar'>
			<li className='nav-link'>
				<Link style={{ fontWeight: 'bold' }} className='nav-link' to='/Home'>
					Shady Guindi
				</Link>
				{/* <a className='nav-link' href='#'>
					Shady Guindi
				</a> */}
			</li>
			<div className='right-links'>
				<li className='nav-link'>
					<Link className='nav-link' to='/About'>
						About Me
					</Link>
				</li>
				<li className='nav-link'>
					<Link className='nav-link' to='/Portfolio'>
						Portfolio
					</Link>
				</li>
			</div>
		</header>
	);
};

export default Navbar;
