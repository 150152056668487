import React, { useState } from 'react';
import PortfolioElements from './PortfolioElement';
import pltrImg from '../../images/pltr.png';
import '../../styles/portfolio.css';
import PEIModal from './PEIModal';
const Portfolio = () => {
	const [modalOn, setModalOn] = useState(false);
	const [modalData, setModalData] = useState({});

	const activateModal = (data) => {
		setModalData(data);
		setModalOn(true);
	};
	const turnModalOff = () => {
		setModalOn(false);
	};
	return (
		<section>
			<div className={modalOn ? 'portfolio-modal' : 'portfolio'}>
				<h1 className='pfo-title'>Welcome to my portfolio</h1>
				<div className='pfo-container'>
					<PortfolioElements
						title='Palentar'
						description='A CRUD applications allowing to schedule your day and create sophisticated to-do lists'
						image={pltrImg}
						date={new Date(2021, 1)}
						skills='backend-frontend communication, database management, email sending, server setup, API development, front-end devlopment, web-design, forms, data encryption, cookies, CRUD application'
						frameWroksLibraries='React js, React Router, Axios, Nodemailer, Node Js, Express Js, JavaScript, css, JSX, Oauth, Passport Js, MongoDB, Mongoose'
						link='/portfolio/palentar'
						activateModal={activateModal}
					/>
					<PortfolioElements
						title='Pathfinder'
						description='A visual application for various pathfinding algorithms'
						// image={pltrImg}
						date={new Date(2021, 3)}
						skills='algorithms and data structures, visual representation, UI design, web-design, front-end development'
						frameWroksLibraries='React js, React Router, Node Js, JavaScript, css, JSX'
						link='/portfolio/pathfinder'
						activateModal={activateModal}
					/>

					{/* <PortfolioElements />
				<PortfolioElements /> */}
				</div>
			</div>
			{modalOn ? (
				<PEIModal
					skills={modalData.skills}
					frameWroksLibraries={modalData.frameWroksLibraries}
					date={modalData.date}
					turnOff={turnModalOff}
				/>
			) : null}
		</section>
	);
};

export default Portfolio;
