import React from 'react';
import PortfolioBox from './PortfolioBox';
import pltrImg from '../../images/book.svg';
import wthrImg from '../../images/Semi-cloudy-night.png';
import xoImg from '../../images/xo.png'


const PortfolioBoxes = () => {
	return (
		<div className='portfolio-boxes'>
			<p>This is the place where I keep all of my software work</p>
			<h2>Here are some of my Projects</h2>
			<div className='boxes-container'>
				<PortfolioBox
					title='Palentar'
					image={pltrImg}
					reference='/portfolio/palentar'
				/>
				<PortfolioBox title='Weather App' image={wthrImg} reference='/Portfolio/weather-app' />
				<PortfolioBox title='TicTacToe AI' image={xoImg} refernce='/portfolio/tictactoe-ai' comingSoon/>
			</div>
		</div>
	);
};

export default PortfolioBoxes;
